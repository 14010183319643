import {
  eItemType,
  eSprintItemStatus,
  ISprintMixSettings,
  ISprintTeamSettingsBase,
  ITicket,
} from '@pwiz/entity/ts';
import { SprintMix } from '../type/sprint-mix';
import { SprintDetails } from '../type';
import { getItemNestedTickets } from '@pwiz/item/ts';
import { getSprintTickets } from './get-sprint-tickets';
import { SprintSystemEffort } from '../type/sprint-calc';
import { getSprintCalcIssueEffort } from './get-sprint-calc-issue-effort';

/**
 * @description
 * Mutates mix
 * @param mix
 * @param type
 * @param ticket
 */
export function addTicketToMix(
  mix: SprintMix,
  ticket: ITicket & SprintSystemEffort,
  type: eItemType,
) {
  const effort = getSprintCalcIssueEffort(ticket);
  mix.total += effort;
  type === eItemType.bug ? (mix.bugs += effort) : (mix.features += effort);
  mix.other -= effort;
}

export function calcMixPercent(
  mix: SprintMix,
  velocity: number,
): ISprintMixSettings {
  return {
    bugs: mix.bugs / velocity,
    features: mix.features / velocity,
    other: mix.other / velocity,
  };
}

export function calcSprintMix(
  sprint: SprintDetails,
  { velocity }: ISprintTeamSettingsBase,
): SprintMix {
  const baseMix = {
    bugs: 0,
    total: 0,
    features: 0,
    other: velocity,
  };
  const sprintTickets = getSprintTickets(sprint.ticketList) as (ITicket &
    SprintSystemEffort)[];
  for (const { item, status } of sprint.itemList) {
    if (status === eSprintItemStatus.omitted) {
      continue;
    }
    const itemTickets = getItemNestedTickets(item);
    sprintTickets.forEach((ticket) => {
      const exists = itemTickets.some(({ id }) => ticket.id === id);
      if (exists) {
        addTicketToMix(baseMix, ticket, item.type);
      }
    });
  }
  return baseMix;
}
