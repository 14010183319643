import { SprintDetails, SprintDetailsRes } from '../../type';
import { toSprintBase } from './to-sprint-calc';
import { ITicket, toItem, toTicket, WithTeam } from '@pwiz/entity/ts';
import { SprintSystemEffort } from '../../type';

export function toSprintDetails({
  ticketList,
  itemList,
  ...sprintDetails
}: SprintDetailsRes): SprintDetails {
  return {
    ...toSprintBase(sprintDetails),
    team: sprintDetails.team,
    ticketList: ticketList.map(({ ticket, ...sprintTicket }) => ({
      ...sprintTicket,
      ticket: toTicket(ticket) as WithTeam<ITicket> & SprintSystemEffort,
    })),
    itemList: itemList.map(({ item, ...sprintItem }) => ({
      ...sprintItem,
      item: toItem(item),
    })),
  };
}
