import { ISprint, toBaseEntitySoftDelete, WithTeam } from '@pwiz/entity/ts';
import { ApiRes } from '@pwiz/infra/ts';

export type SprintRes = ApiRes<WithTeam<ISprint>>;

export function toSprint({
  team,
  startDate,
  endDate,
  ...sprintRes
}: SprintRes): WithTeam<ISprint> {
  return {
    ...toBaseEntitySoftDelete(sprintRes),
    team: toBaseEntitySoftDelete(team),
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
}
