import { SprintEditableTableItem, SprintEditChangeRes } from '../../type';

export function reduceSprintEditChangeRes({
  systemRemovedItemsList,
  systemAddedItemsList,
  systemChangedItemList,
}: SprintEditChangeRes): SprintEditableTableItem[] {
  return [
    ...systemAddedItemsList.map(
      (item) => <SprintEditableTableItem>{ ...item, action: 'add' },
    ),
    ...systemRemovedItemsList.map(
      (item) => <SprintEditableTableItem>{ ...item, action: 'remove' },
    ),
    ...systemChangedItemList.map(
      (item) => <SprintEditableTableItem>{ ...item, action: 'change' },
    ),
  ];
}
