import { ISprintSettings, ISprintTeamSettings } from '@pwiz/entity/ts';

export function updateSprintSettings(
  newSettings: Partial<ISprintSettings>,
  oldSettings: ISprintSettings | null,
): ISprintSettings {
  if (!oldSettings) {
    return newSettings as ISprintSettings;
  }
  const global = newSettings.global
    ? { ...oldSettings.global, ...newSettings.global }
    : oldSettings.global;
  const boards = newSettings.teams
    ? combineBoardSettings(oldSettings.teams, newSettings.teams)
    : oldSettings.teams;
  return {
    global,
    teams: boards,
  };
}

function combineBoardSettings(
  oldBoards: Record<string, ISprintTeamSettings>,
  newBoards: Record<string, ISprintTeamSettings>,
) {
  return Object.keys(oldBoards).reduce<Record<string, ISprintTeamSettings>>(
    (acc, boardId) => {
      acc[boardId] = { ...oldBoards[boardId], ...newBoards[boardId] };
      return acc;
    },
    {},
  );
}
