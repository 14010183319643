import { eSprintTicketStatus } from '@pwiz/entity/ts';

export const SPRINT_TICKET_CONNECTION_STATUS_LIST: eSprintTicketStatus[] = [
  eSprintTicketStatus.manually,
  eSprintTicketStatus.forced,
  eSprintTicketStatus.omitted,
  eSprintTicketStatus.manuallyByItem,
  eSprintTicketStatus.auto,
] as const;

export const SPRINT_TICKET_CONNECTION_AUTO_ADDED_STATUS_LIST = [
  eSprintTicketStatus.auto,
  eSprintTicketStatus.forced,
];
