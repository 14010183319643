import { computed, inject, Injectable } from '@angular/core';
import {
  CanCommitSprintRes,
  ItemSprintSchedule,
  ItemSprintScheduleRes,
  SelectSprintTicketRes,
  SelectSprintTicketsReq,
  SPRINT_API,
  SPRINT_API_ENDPOINTS,
  SprintCalcItem,
  SprintDetails,
  SprintDetailsRes,
  SprintEditChangeRes,
  SprintEditReq,
  SprintItemSearch,
  SprintItemSearchRes,
  SprintListReq,
  SprintRawRes,
  SprintRes,
  SprintViewRes,
  toItemSprintSchedule,
  toSprint,
  toSprintCalc,
  toSprintDetails,
  toSprintView,
  updateSprintSettings,
} from '@pwiz/sprint/ts';
import { HttpClient } from '@angular/common/http';
import { filter, map, Observable, Subject } from 'rxjs';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { ApiRes, filterNotNull, toHttpQueryParams } from '@pwiz/infra/ts';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  eSprintStatus,
  ISprint,
  ISprintSettings,
  ISprintSettingsResponse,
  ISprintTeamSettingsBase,
  ItemResponse,
  toItem,
} from '@pwiz/entity/ts';
import { toSprintSettings } from 'infra/ui-entity';
import { PwCache, toPwCache } from '@pwiz/infra/cache/ts';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SprintService {
  #baseUrl = useBaseApiUrl(SPRINT_API);

  #http = inject(HttpClient);

  #sprintSettingsCache = new PwCache<ISprintSettings>(
    null,
    updateSprintSettings,
    this.#getSprintSettings$(),
  );

  #sprintChange$ = new Subject<{ id: string }>();

  $sprintSettings = toSignal(this.#sprintSettingsCache.getV2$(), {
    requireSync: true,
  });

  $sprintTeams = computed(() => {
    const teamMap = this.$sprintSettings().data?.teams || {};
    return Object.entries(teamMap).map(([, teamSettings]) => teamSettings.team);
  });
  getSprintSettings$() {
    return this.#sprintSettingsCache.getV2$().pipe(
      map((cache) => cache.data),
      filter(filterNotNull),
    );
  }

  sprintChanged$() {
    return this.#sprintChange$.asObservable();
  }

  saveSpecificSprintSettings(
    sprint: SprintDetails,
    settings: ISprintTeamSettingsBase,
  ) {
    return this.#http
      .post<SprintRawRes>(
        `${this.#baseUrl}/${SPRINT_API_ENDPOINTS.specificSprintSettings}`,
        { settings, sprint },
      )
      .pipe(
        tap(() => this.#sprintChange$.next({ id: sprint.id })),
        map((sprint) => toSprintCalc(sprint)),
      );
  }

  saveSprintSettings(settings: ISprintSettings) {
    this.#sprintSettingsCache.update(settings);
    return this.#http
      .post<ISprintSettingsResponse>(
        `${this.#baseUrl}/${SPRINT_API_ENDPOINTS.settings}`,
        { settings },
      )
      .pipe(map(toSprintSettings));
  }

  getSprint$(sprintId: string, teamList: string[]) {
    return this.#http
      .get<SprintDetailsRes>(`${this.#baseUrl}/${sprintId}`, {
        params: { teamList },
      })
      .pipe(map(toSprintDetails));
  }

  getSprintList(sprintListReq: SprintListReq) {
    return this.#http
      .get<SprintViewRes[]>(`${this.#baseUrl}/${SPRINT_API_ENDPOINTS.list}`, {
        params: toHttpQueryParams(sprintListReq),
      })
      .pipe(
        map((sprintList) => sprintList.map(toSprintView)),
        toPwCache(),
      );
  }

  searchItemsForSprint$(
    search: string,
    sprintId: string,
    teamIdList: string[],
  ) {
    return this.#http
      .get<SprintItemSearchRes[]>(
        `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.searchItems}`,
        {
          params: {
            search,
            teamList: teamIdList,
          },
        },
      )
      .pipe(map((itemList) => itemList.map(toItem<SprintItemSearch>)));
  }

  mockEditSprint(sprintId: string, editReq: SprintEditReq) {
    return this.#http.post<SprintEditChangeRes>(
      `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.mockEdit}`,
      editReq,
    );
  }

  editSprint(sprintId: string, editReq: SprintEditReq) {
    return this.#http
      .post<SprintDetailsRes>(
        `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.editItems}`,
        editReq,
      )
      .pipe(map(toSprintDetails));
  }

  getItemSchedule(itemId: string, teamId?: string) {
    return this.#http
      .get<ItemSprintScheduleRes>(
        `${this.#baseUrl}/${SPRINT_API_ENDPOINTS.itemSchedule}`,
        { params: toHttpQueryParams({ itemId, teamId }) },
      )
      .pipe(map(toItemSprintSchedule));
  }

  saveSprintItemTickets$(
    sprintId: string,
    selectSprintTicketReq: SelectSprintTicketsReq,
  ) {
    return this.#http
      .post<SelectSprintTicketRes>(
        `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.selectTickets}`,
        selectSprintTicketReq,
      )
      .pipe(map(toSprintDetails));
  }

  #getSprintSettings$() {
    return this.#http
      .get<ISprintSettingsResponse>(
        `${this.#baseUrl}/${SPRINT_API_ENDPOINTS.settings}`,
      )
      .pipe(map(toSprintSettings));
  }

  commitSprint(sprintId: string): Observable<ISprint> {
    return this.#http
      .put<SprintRes>(
        `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.commit}`,
        {},
      )
      .pipe(map(toSprint));
  }

  canCommitSprint(sprintId: string) {
    return this.#http.get<CanCommitSprintRes>(
      `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.canCommit}`,
    );
  }

  unCommitSprint(sprintId: string) {
    return this.#http
      .put<SprintRes>(
        `${this.#baseUrl}/${sprintId}/${SPRINT_API_ENDPOINTS.uncommit}`,
        {},
      )
      .pipe(map(toSprint));
  }
}
