export const SPRINT_API = 'sprint' as const;
export const SPRINT_API_ENDPOINTS = {
  list: 'list',
  save: 'save',
  settings: 'settings',
  specificSprintSettings: 'specific-settings',
  searchItems: 'search-items',
  mockEdit: 'mock-edit',
  editItems: 'edit-items',
  itemSchedule: 'item-schedule',
  selectTickets: 'select-tickets',
  commit: 'commit',
  uncommit: 'uncommit',
  canCommit: 'can-commit',
} as const;
