@if (ticket(); as myTicket) {
  @if (disableLink()) {
    <p
      matTooltip="[{{ myTicket.displayId }}] - {{ myTicket.summery }}"
    >
      {{ myTicket.displayId }}
    </p>
  } @else {
    <pw-external-link
      [href]="myTicket.url"
      matTooltip="[{{ myTicket.displayId }}] - {{ myTicket.summery }}"
    >
      {{ myTicket.displayId }}
    </pw-external-link>
  }
  @if (!hideIcon()) {
    @if (!!ticket().parentItem) {
      <pw-item-type-icon class="icon" [type]="ticket().parentItem!.type" />
    } @else {
      <pw-ticket-icon class="icon"/>
    }
  }
}
