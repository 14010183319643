import { eSprintItemStatus } from '@pwiz/entity/ts';

export const SPRINT_ITEM_CONNECTION_STATUS_LIST: eSprintItemStatus[] = [
  eSprintItemStatus.manually,
  eSprintItemStatus.forced,
  eSprintItemStatus.omitted,
  eSprintItemStatus.auto,
] as const;

export const SPRINT_ITEM_CONNECTION_AUTO_ADDED_STATUS_LIST = [
  eSprintItemStatus.auto,
  eSprintItemStatus.forced,
];
