import { EpicSprintSchedule, ItemSprintSchedule } from '../../type';
import { getItemEpics } from '@pwiz/item/ts';
import { isEpic } from '@pwiz/entity/ts';

export function getItemSprintScheduleEpic(
  itemSchedule: ItemSprintSchedule,
  epicId: string,
): ItemSprintSchedule {
  const itemEpics = getItemEpics(itemSchedule);
  const epic = itemEpics
    .filter(isEpic)
    .find((epic) => epic.id === epicId) as EpicSprintSchedule;
  return {
    ...itemSchedule,
    initiative: [],
    epic: epic ? [epic] : [],
    ticket: [],
  };
}
