import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pw-ticket-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './private/shared.scss',
  template: `
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_201_7"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_201_7)">
        <path
          d="M11.9988 24C15.2044 24 18.2188 22.7513 20.485 20.485C22.7513 18.2188 24 15.2044 24 11.9988C24 8.79317 22.7513 5.78004 20.485 3.51317C18.2188 1.24692 15.2044 -0.00183105 11.9988 -0.00183105C8.79317 -0.00183105 5.78004 1.24692 3.51317 3.51317C1.24629 5.77942 -0.00183105 8.79317 -0.00183105 11.9988V24H11.9988ZM0.478794 11.9988C0.478794 8.92192 1.67692 6.02879 3.85254 3.85254C6.02817 1.67629 8.92192 0.478794 11.9988 0.478794C15.0763 0.478794 17.9694 1.67692 20.1457 3.85254C22.3213 6.02879 23.5194 8.92192 23.5194 11.9988C23.5194 15.0763 22.3213 17.9694 20.1457 20.1457C17.9694 22.3213 15.0763 23.5194 11.9988 23.5194H0.478794V11.9988Z"
          fill="#11AB4A"
        />
      </g>
    </svg>
  `,
})
export class TicketIconComponent {}
