export const SPRINT_PAGE_STATUSES = [
  'recommended',
  'in planing',
  'done',
  'current',
  'next',
] as const;

export const AUTO_SPRINT_PAGE_STATUSES = ['recommended', 'in planing'] as const;

export const COMMITTED_SPRINT_PAGE_STATUSES = [
  'done',
  'current',
  'next',
] as const;
