import { SprintDetails, UserSprintInfo } from '../../type';
import { eSprintTicketStatus } from '@pwiz/entity/ts';
import { getSprintCalcIssueEffort } from '../get-sprint-calc-issue-effort';
import { SprintDetailsAssigneeFilter } from '../../type/sprint-table/sprint-details-assignee-filter';

const UNASSIGNED_ID = '__unassigned' as const;

export function getAssigneesEffortInSprint(sprint: SprintDetails): {
  assigned: UserSprintInfo[];
  unassigned: UserSprintInfo | null;
} {
  const assigneeMap = new Map<string, UserSprintInfo>();
  for (const { ticket, status } of sprint.ticketList) {
    if (status === eSprintTicketStatus.omitted) {
      continue;
    }
    const assigneeId = ticket.providerAssigneeId || UNASSIGNED_ID;
    const existing = assigneeMap.get(assigneeId);
    if (existing) {
      existing.currentEffort += getSprintCalcIssueEffort(ticket);
    } else {
      assigneeMap.set(assigneeId, {
        user: {
          id: assigneeId,
        },
        currentEffort: getSprintCalcIssueEffort(ticket),
      });
    }
  }
  const unassigned = assigneeMap.get(UNASSIGNED_ID) || null;
  assigneeMap.delete(UNASSIGNED_ID);
  return {
    unassigned,
    assigned: Array.from(assigneeMap.values()),
  };
}

export function getSprintAssigneeFilter(
  assigneeList: string[],
): SprintDetailsAssigneeFilter {
  const assigneeIdList: string[] = assigneeList.filter(
    (id) => id !== UNASSIGNED_ID,
  );
  return {
    assigneeIdList,
    includeUnassigned: assigneeIdList.length !== assigneeList.length,
  };
}
