export const SPRINT_DETAILS_VIEW_LEVEL = [
  'features',
  'epics',
  'tickets',
] as const;
export type SprintDetailsViewLevel = (typeof SPRINT_DETAILS_VIEW_LEVEL)[number];

export function isSprintDetailsViewLevel(
  level: string,
): level is SprintDetailsViewLevel {
  return SPRINT_DETAILS_VIEW_LEVEL.some((viewLevel) => viewLevel === level);
}
