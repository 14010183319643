import { Pipe, PipeTransform } from '@angular/core';
import { getSprintIssueEffort, SprintCalcTicket } from '@pwiz/sprint/ts';
import { pwToFixed } from '@pwiz/infra/ts';

@Pipe({
  name: 'pwSprintTicketEffort',
  standalone: true,
})
export class PwSprintTicketEffortPipe implements PipeTransform {
  transform(
    value: Pick<SprintCalcTicket, 'effortEstimation' | 'systemEffort'>,
  ) {
    return pwToFixed(getSprintIssueEffort(value), 1);
  }
}
