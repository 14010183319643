import {
  SprintDetailsItemConnection,
  SprintDetailsTicketConnection,
} from '../../type';
import { getItemNestedTickets } from '@pwiz/item/ts';
import { eSprintTicketStatus } from '@pwiz/entity/ts';
import { getSprintIssueEffort } from '../sprint-calc-v2';

export function getSprintDetailItemsByTicketIdList(
  itemList: SprintDetailsItemConnection[],
  sprintTicketList: SprintDetailsTicketConnection[],
) {
  const itemsWithTickets: SprintDetailsItemConnection[] = [];
  for (const sprintItem of itemList) {
    const itemTickets = getItemNestedTickets(sprintItem.item);
    const ticketsInList: SprintDetailsTicketConnection['ticket'][] = [];
    for (const sprintTicket of sprintTicketList) {
      if (sprintTicket.status === eSprintTicketStatus.omitted) {
        continue;
      }
      if (itemTickets.find(({ id }) => sprintTicket.ticket.id === id)) {
        ticketsInList.push(sprintTicket.ticket);
      }
    }
    if (!ticketsInList.length) {
      continue;
    }
    itemsWithTickets.push({
      ...sprintItem,
      stats: {
        ticket: {
          ...sprintItem.stats.ticket,
          inSprint: ticketsInList.length,
        },
        effort: {
          ...sprintItem.stats.effort,
          inSprint: ticketsInList.reduce(
            (effort, ticket) => effort + getSprintIssueEffort(ticket),
            0,
          ),
        },
      },
    });
  }
  return itemsWithTickets;
}
