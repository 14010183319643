import {
  queryParamToArray,
  queryParamToBoolean,
  queryParamToStringOption,
  ToQueryParam,
} from '@pwiz/infra/ts';
import {
  SPRINT_DETAILS_VIEW_LEVEL,
  SprintDetailsViewLevel,
} from '../sprint-details-view-level';

export interface SprintDetailsAssigneeFilter {
  assigneeIdList: string[];
  includeUnassigned: boolean;
}

export interface SprintTableViewLevel {
  view: SprintDetailsViewLevel;
}

export type SprintTableFilter = SprintDetailsAssigneeFilter &
  SprintTableViewLevel;

export type SprintTableFilterParams = ToQueryParam<SprintTableFilter>;

export function toSprintTableFilter({
  view,
  includeUnassigned,
  assigneeIdList,
}: SprintTableFilterParams): SprintTableFilter {
  return {
    view: queryParamToStringOption(SPRINT_DETAILS_VIEW_LEVEL, view, 'features'),
    includeUnassigned: queryParamToBoolean(includeUnassigned),
    assigneeIdList: queryParamToArray(assigneeIdList),
  };
}

export function toSprintDetailsAssigneeFilter({
  assigneeIdList,
  includeUnassigned,
}: SprintTableFilter): SprintDetailsAssigneeFilter {
  return {
    assigneeIdList,
    includeUnassigned,
  };
}
