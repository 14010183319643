import { SprintEditableTableItem } from '../type';

export function sprintItemEditArrToAddRemoveLists(
  itemsToChange: SprintEditableTableItem[],
) {
  return {
    addItemsList: itemsToChange.filter(({ action }) => action === 'add'),
    removeItemsList: itemsToChange.filter(({ action }) => action === 'remove'),
  };
}
