import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { getIssueItem } from '@pwiz/ticket/ts';
import { IIssue, IItem, PwIssue } from '@pwiz/entity/ts';
import { ExternalLinkComponent } from '@pwiz/infra/ui';
import { TicketIconComponent } from '@pwiz/infra/icons/ui';
import { ItemTypeIconComponent } from '@pwiz/item-common/ui';

@Component({
  selector: 'pw-ticket-id',
  standalone: true,
  imports: [
    CommonModule,
    ItemTypeIconComponent,
    MatIconModule,
    MatTooltipModule,
    ExternalLinkComponent,
    TicketIconComponent,
  ],
  templateUrl: './ticket-id.component.html',
  styleUrl: './ticket-id.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketIdComponent {
  ticket = input.required<PwIssue & { parentItem: IItem | null }, IIssue>({
    transform: (ticket: PwIssue) =>
      ({
        ...ticket,
        parentItem: getIssueItem(ticket),
      }) as PwIssue & { parentItem: IItem | null },
  });

  disableLink = input(false, { transform: booleanAttribute });

  hideIcon = input(false, {
    transform: booleanAttribute,
  });
}
