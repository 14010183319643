import {
  computed,
  effect,
  inject,
  Injectable,
  signal,
  untracked,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { SprintService } from './sprint.service';
import { SprintCalcService } from './sprint-calc.service';
import {
  distinctUntilChanged,
  map,
  NEVER,
  of,
  ReplaySubject,
  share,
  shareReplay,
  Subject,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { PwCacheEntity } from '@pwiz/infra/cache/ts';
import {
  isSprintCommitted,
  ItemSprintSchedule,
  reduceSprintEditChangeRes,
  SprintDetails,
  SprintEditableTableItem,
  SprintItemChange,
  sprintItemEditArrToAddRemoveLists,
  TicketSprintSchedule,
} from '@pwiz/sprint/ts';
import { BaseEntityUtils } from '@pwiz/entity/ts';
import { tap } from 'rxjs/operators';
import { useReplaySubject } from '@pwiz/infra/hooks';

@Injectable({
  providedIn: 'root',
})
export class CurrentSprintService {
  #sprintService = inject(SprintService);
  #sprintCalcService = inject(SprintCalcService);
  #sprintId$ = useReplaySubject<string>();
  #sprintCache = new PwCacheEntity<SprintDetails>(
    null,
    NEVER,
    undefined,
    undefined,
    'sprint',
  );
  $selectedTeams = this.#sprintCalcService.$selectedTeams;
  $sprint = toSignal(this.#sprintCache.getV2$(), { requireSync: true });

  $sprintSettings = computed(() => {
    const { data } = this.$sprint();
    if (data?.settings) {
      return data.settings;
    }
    const settings = this.#sprintService.$sprintSettings().data;
    if (!settings) {
      return null;
    }
    const teamIdList = this.#sprintCalcService.$teamIdList();
    return settings.teams[teamIdList[0]];
  });

  constructor() {
    this.#setCacheDataSource();
    this.#sprintService
      .sprintChanged$()
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.#reFetchCache());
  }

  #reFetchCache() {
    /**
     * It takes some time until the server recalculates the sprints.
     */
    setTimeout(() => this.#sprintCache.reFetch(), 1000);
  }

  set sprintId(sprintId: string) {
    this.#sprintId$.next(sprintId);
  }

  get #teamIdList() {
    return untracked(() => this.#sprintCalcService.$teamIdList());
  }
  #setCacheDataSource() {
    const teamIdList = this.#teamIdList;
    if (!teamIdList) {
      return;
    }
    this.#sprintId$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((sprintId) =>
        this.#sprintCache.setDatasource(
          btoa(
            JSON.stringify({
              teams: this.#sprintCalcService.$teamIdList(),
              sprintId,
            }),
          ),
          this.#sprintService.getSprint$(sprintId, teamIdList),
        ),
      );
  }

  searchItems$(search: string) {
    return this.#sprintId$.pipe(
      switchMap((sprintId) =>
        this.#sprintService.searchItemsForSprint$(
          search,
          sprintId,
          this.#sprintCalcService.$teamIdList(),
        ),
      ),
    );
  }

  getSprintChangeBeforeEdit(itemsToChange: SprintEditableTableItem[]) {
    if (!itemsToChange.length) {
      return of<SprintItemChange>({ systemItems: [], sprintItems: [] });
    }
    const { addItemsList, removeItemsList } =
      sprintItemEditArrToAddRemoveLists(itemsToChange);
    return this.#sprintService
      .mockEditSprint(this.$sprint().data!.id, {
        teamIdList: this.#sprintCalcService.$teamIdList(),
        addItemsList: BaseEntityUtils.toIdList(addItemsList),
        removeItemsList: BaseEntityUtils.toIdList(removeItemsList),
      })
      .pipe(
        map((res) => ({
          systemItems: reduceSprintEditChangeRes(res),
          sprintItems: res.sprintItems,
        })),
      );
  }

  editSprint(itemsToChange: SprintEditableTableItem[]) {
    if (!itemsToChange.length) {
      throw new Error(`To add items to sprint, provide items`);
    }
    const { addItemsList, removeItemsList } =
      sprintItemEditArrToAddRemoveLists(itemsToChange);
    return this.#sprintService
      .editSprint(this.$sprint().data!.id, {
        teamIdList: this.#sprintCalcService.$teamIdList(),
        addItemsList: BaseEntityUtils.toIdList(addItemsList),
        removeItemsList: BaseEntityUtils.toIdList(removeItemsList),
      })
      .pipe(tap(() => this.#sprintCache.reFetch()));
  }

  saveSprintITemTickets$(
    item: ItemSprintSchedule,
    selectedTicketIdList: TicketSprintSchedule[],
    epicId?: string | null,
  ) {
    return this.#sprintId$.pipe(
      switchMap((sprintId) =>
        this.#sprintService.saveSprintItemTickets$(sprintId, {
          itemId: item.id,
          ticketIdList: BaseEntityUtils.toIdList(selectedTicketIdList),
          teamList: this.#sprintCalcService.$teamIdList(),
          isEpic: !!epicId,
        }),
      ),
      tap(() => this.#sprintCache.reFetch()),
    );
  }

  commitSprint() {
    return this.#sprintCache.reFetch(
      this.#sprintId$.pipe(
        switchMap((sprintId) => this.#sprintService.commitSprint(sprintId)),
        take(1),
      ),
    );
  }

  $isCommitted() {
    return computed(() => {
      const sprint = this.$sprint().data;
      if (!sprint) {
        return false;
      }
      return isSprintCommitted(sprint);
    });
  }

  commitActions$() {
    return this.#sprintCache
      .getV2$()
      .pipe(
        switchMap(({ data }) =>
          data ? this.#sprintService.canCommitSprint(data.id) : of(null),
        ),
      );
  }

  unCommitSprint() {
    return this.#sprintCache.reFetch(
      this.#sprintId$.pipe(
        switchMap((sprintId) => this.#sprintService.unCommitSprint(sprintId)),
        take(1),
      ),
    );
  }
}
