import { SprintBaseRes, SprintRawRes } from '../../type';
import { ISprint, toBaseEntity, toItem, toTicket } from '@pwiz/entity/ts';

export function toSprintBase(
  sprintRes: Omit<SprintBaseRes, 'ticketList' | 'itemList'>,
): ISprint {
  return {
    ...toBaseEntity(sprintRes),
    endDate: new Date(sprintRes.endDate),
    startDate: new Date(sprintRes.startDate),
  };
}
export function toSprintCalc<T extends SprintRawRes = SprintRawRes>(
  sprintRes: T,
) {
  return {
    ...toSprintBase(sprintRes),
    ticketList: sprintRes.ticketList.map(({ ticket, ...sprintTicket }) => ({
      ...sprintTicket,
      ticket: toTicket(ticket),
    })),
    itemList: sprintRes.itemList.map(({ item, ...sprintItem }) => ({
      ...sprintItem,
      item: toItem(item),
    })),
  };
}
