import {
  eSprintItemStatus,
  ISprintItemConnection,
  OptionalBaseEntitySoftDelete,
} from '@pwiz/entity/ts';
import { listIncludeId } from './sprint-calc-v2/util/list-include-id';

/**
 *
 * @param itemList
 * @param statusesToInclude - when null will include all un-excluded statuses
 * @param statusesToExclude - when null will not exclude any status
 */
export function getSprintItems<
  T extends OptionalBaseEntitySoftDelete<ISprintItemConnection>,
>(
  itemList: T[],
  statusesToInclude: eSprintItemStatus[] | null,
  statusesToExclude: eSprintItemStatus[] | null,
): T['item'][] {
  const sprintItemConnectionList = getSprintItemConnectionList(
    itemList,
    statusesToInclude,
    statusesToExclude,
  );
  return sprintItemConnectionList.map(({ item }) => item);
}

/**
 *
 * @param itemList
 * @param statusesToInclude - when null will include all un-excluded statuses
 * @param statusesToExclude - when null will not exclude any status
 */
export function getSprintItemConnectionList<
  T extends OptionalBaseEntitySoftDelete<ISprintItemConnection>,
>(
  itemList: T[],
  statusesToInclude: eSprintItemStatus[] | null,
  statusesToExclude: eSprintItemStatus[] | null,
): T[] {
  const newList: T[] = [];
  for (const sprintItem of itemList) {
    if (
      statusesToExclude &&
      listIncludeId(statusesToExclude, sprintItem.status)
    ) {
      continue;
    }
    if (
      statusesToInclude &&
      !listIncludeId(statusesToInclude, sprintItem.status)
    ) {
      continue;
    }
    newList.push(sprintItem);
  }
  return newList;
}

export function getSprintItemConnectionByItem<
  T extends OptionalBaseEntitySoftDelete<ISprintItemConnection>,
>(list: T[], itemId: string) {
  for (const sprintItem of list) {
    if (sprintItem.item?.id === itemId) {
      return sprintItem;
    }
  }
  return null;
}
