import {
  ItemScheduleTreeNestedNode,
  ItemScheduleTreeNode,
  SprintCalcIssue,
  SprintMini,
} from '../type';
import { getSprintIssueEffort } from './sprint-calc-v2';

export function toItemScheduleTreeNode(
  issue: SprintCalcIssue,
  startSprint: SprintMini | null,
  endSprint: SprintMini | null,
): ItemScheduleTreeNode {
  return {
    issue: { ...issue },
    effortEstimation: getSprintIssueEffort(issue),
    team: issue.team || null,
    title: issue.summery,
    displayId: issue.displayId,
    status: issue.status,
    startSprint,
    endSprint,
    children: undefined,
  };
}

export function doesItemScheduleNodeHaveChildren(
  node: ItemScheduleTreeNode,
): node is ItemScheduleTreeNestedNode {
  return !!node.children && !!node.children.length;
}

export function getItemScheduleTreeNodeChildren(
  node: ItemScheduleTreeNode,
): ItemScheduleTreeNode[] {
  if (!doesItemScheduleNodeHaveChildren(node)) {
    return [node];
  }
  return node.children.reduce<ItemScheduleTreeNode[]>(
    (allChildren, childNode) => {
      const grandChildren = getItemScheduleTreeNodeChildren(childNode);
      if (!grandChildren) {
        return allChildren;
      }
      return [...allChildren, ...grandChildren];
    },
    [],
  );
}
