import { computed, Injectable } from '@angular/core';
import { ITeam, WithBoard } from '@pwiz/entity/ts';
import { filterNotNull, queryParamToArray } from '@pwiz/infra/ts';
import { $useTeams } from '@pwiz/team/ui-data';
import { useMappedQueryParams } from '@pwiz/infra/query-params/data';

@Injectable({ providedIn: 'root' })
export class SprintCalcService {
  $teamIdList = useMappedQueryParams<string[]>({}, (params) =>
    queryParamToArray(params['teams']),
  ).$filters;

  #$allTeams = $useTeams();

  $selectedTeams = computed<WithBoard<ITeam>[]>(() => {
    const teams = this.$teamIdList();
    const allTeams = this.#$allTeams() || [];
    if (!teams) {
      return [];
    }
    return teams
      .map((teamId) => allTeams.find(({ id }) => id === teamId))
      .filter(filterNotNull<WithBoard<ITeam>>);
  });
}
