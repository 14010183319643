import { SprintDetails } from '../../../type';
import { listIncludeId } from './list-include-id';

export function getSprintTicketsByAssignee<
  T extends SprintDetails['ticketList'][number],
>(sprintTicketList: T[], assigneeIdList: string[], includeUnassigned: boolean) {
  const tickets: T[] = [];
  for (const sprintTicket of sprintTicketList) {
    const assigneeId = sprintTicket.ticket.providerAssigneeId;
    if (assigneeId && listIncludeId(assigneeIdList, assigneeId)) {
      tickets.push(sprintTicket);
      continue;
    }
    if (includeUnassigned && !assigneeId) {
      tickets.push(sprintTicket);
    }
  }
  return tickets;
}
