import {
  ItemSprintSchedule,
  ItemSprintScheduleRes,
  SprintMini,
} from '../../type';
import { toItem } from '@pwiz/entity/ts';
import { ApiRes } from '@pwiz/infra/ts';

export function toItemSprintSchedule(
  scheduleRes: ItemSprintScheduleRes,
): ItemSprintSchedule {
  const { initiative, ticket, epic, ...item } =
    toItem<ItemSprintSchedule>(scheduleRes);
  return {
    ...item,
    initiative: initiative?.map((ini) => ({
      ...ini,
      startSprint: toSprintMini(ini.startSprint as ApiRes<SprintMini> | null),
      endSprint: toSprintMini(ini.endSprint as ApiRes<SprintMini> | null),
      children: mapEpic(ini.children),
    })),
    epic: mapEpic(epic),
    ticket: mapTickets(ticket),
  };
}

function mapEpic(
  epicList: ItemSprintSchedule['epic'],
): ItemSprintSchedule['epic'] {
  return epicList.map(({ children, startSprint, endSprint, ...epic }) => ({
    ...epic,
    children: mapTickets(children),
    startSprint: toSprintMini(startSprint as ApiRes<SprintMini> | null),
    endSprint: toSprintMini(endSprint as ApiRes<SprintMini> | null),
  }));
}
function mapTickets(
  ticketList: ItemSprintSchedule['ticket'],
): ItemSprintSchedule['ticket'] {
  return ticketList.map((ticket) => ({
    ...ticket,
    sprint: toSprintMini(ticket.sprint as ApiRes<SprintMini> | null),
  }));
}

function toSprintMini(sprint: ApiRes<SprintMini> | null): SprintMini | null {
  if (!sprint) {
    return null;
  }
  return {
    ...sprint,
    startDate: new Date(sprint.startDate),
    endDate: new Date(sprint.endDate),
  };
}
