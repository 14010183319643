import {
  SprintDetailsItemConnection,
  SprintEditableTableItem,
} from '../../../type';
import { eSprintItemStatus } from '@pwiz/entity/ts';

export function sprintItemDetailsToSprintEditableItem({
  item,
  status,
  stats,
}: SprintDetailsItemConnection): SprintEditableTableItem {
  return {
    ...item,
    startSprint: '',
    endSprint: '',
    sprintEffort: stats.effort.inSprint,
    action: status === eSprintItemStatus.manually ? 'add' : 'remove',
  };
}
