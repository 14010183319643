import {
  EpicSprintSchedule,
  InitiativeSprintSchedule,
  ItemSprintSchedule,
  SprintItemScheduleFilter,
  TicketSprintSchedule,
} from '../../type';

export function filterSprintItemSchedule(
  schedule: ItemSprintSchedule,
  filter: SprintItemScheduleFilter,
): ItemSprintSchedule {
  return {
    ...schedule,
    initiative: filterInitiative(schedule.initiative, filter),
    epic: filterEpic(schedule.epic, filter),
    ticket: filterTicket(schedule.ticket, filter),
  };
}

function filterInitiative(
  initiativeList: InitiativeSprintSchedule[],
  { team, active }: SprintItemScheduleFilter,
) {
  return initiativeList
    .filter((initiative) => {
      if (team) {
        if (!initiative.isTeamIssue) {
          return false;
        }
      }
      return (active && initiative.isActive) || !active;
    })
    .map(({ children, ...initiative }) => ({
      ...initiative,
      children: filterEpic(children, { team, active }),
    }));
}

function filterEpic(
  epicList: EpicSprintSchedule[],
  { team, active }: SprintItemScheduleFilter,
) {
  return epicList
    .filter((epic) => {
      if (team) {
        if (!epic.isTeamIssue) {
          return false;
        }
      }
      return (active && epic.isActive) || !active;
    })
    .map(({ children, ...epic }) => ({
      ...epic,
      children: filterTicket(children, { team, active }),
    }));
}

function filterTicket(
  ticketList: TicketSprintSchedule[],
  { team: filterTeam, active }: SprintItemScheduleFilter,
) {
  return ticketList.filter((ticket) => {
    if (filterTeam) {
      if (!ticket.isTeamIssue) {
        return false;
      }
    }
    return (active && ticket.isActive) || !active;
  });
}
