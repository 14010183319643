import {
  eSprintTicketStatus,
  ISprintTicketConnection,
  OptionalBaseEntitySoftDelete,
} from '@pwiz/entity/ts';
import { PwRequireProperty } from '@pwiz/infra/ts';

export function getSprintTickets(
  ticketList: OptionalBaseEntitySoftDelete<
    PwRequireProperty<ISprintTicketConnection, 'ticket'>
  >[],
) {
  return getSprintTicketV2(ticketList, [eSprintTicketStatus.omitted]);
}

export function getSprintTicketV2<
  T extends PwRequireProperty<
    Pick<ISprintTicketConnection, 'status' | 'ticket'>,
    'ticket'
  >,
>(ticketList: T[], excludeStatusList: eSprintTicketStatus[] | null) {
  if (!ticketList) {
    return [];
  }
  const noOmittedTickets: T['ticket'][] = [];
  for (const { ticket, status } of ticketList) {
    if (excludeStatusList && excludeStatusList.includes(status)) {
      continue;
    }
    noOmittedTickets.push(ticket);
  }
  return noOmittedTickets;
}
