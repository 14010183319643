import { eSprintStatus, ISprint } from '@pwiz/entity/ts';
import { isCurrentSprint, isDoneSprint, isNextSprint } from './is';

const map: Record<eSprintStatus, string> = {
  [eSprintStatus.inProgress]: 'current',
  [eSprintStatus.committed]: 'next',
  [eSprintStatus.saved]: 'in planing',
  [eSprintStatus.auto]: 'recommended',
  [eSprintStatus.done]: 'done',
};

export function getSprintUiStatus(sprint: ISprint) {
  if (sprint.status === eSprintStatus.committed) {
    return getCommittedSprintStatus(sprint);
  }
  return map[sprint.status];
}

function getCommittedSprintStatus(sprint: ISprint) {
  if (isCurrentSprint(sprint)) {
    return 'current';
  }
  if (isNextSprint(sprint)) {
    return 'next';
  }
  if (isDoneSprint(sprint)) {
    return 'done';
  }
  return 'next';
}
